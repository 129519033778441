import { useIntl } from "react-intl";
import { NavLink } from "../../../../../app/pages/main-page/components/NavLink";
import { FreeBetLogo } from "../../../../../app/pages/main-page/components/FreeBetLogo";
import { Link } from "react-router-dom";
import { headerMenuList } from "./header-menu-list";
import { useAuth } from "../../../../../app/modules/auth";

export function MenuInner() {
  const intl = useIntl();

  const { logout } = useAuth();

  return (
    <>
      <div className="py-5 px-10 p-lg-0 container text-center align-items-center align-self-start align-self-lg-center">
        <div className="row align-items-center flex-column flex-lg-row fs-4 fs-lg-6">
          <div className="col col-lg-4 text-start ">
            <NavLink title={<FreeBetLogo />} to="/" />
          </div>

          <ul className="d-lg-none mt-8">
            {headerMenuList.map((el: { href: string; name: string }) => (
              <li className="menu-item  ">
                <Link to={el.href} className="text-white menu-link ">
                  {el.name}
                </Link>
              </li>
            ))}

            <li className="menu-item ">
              <a onClick={logout} className="text-danger menu-link ">
                {intl.formatMessage({ id: "AUTH.LOGOUT.TITLE" })}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
