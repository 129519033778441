import { FC, lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { MainPage } from "../pages/main-page";
import { ConfirmOperation } from "../pages/ConfirmOperation";
import {
  confirmFreewinWithdrawal,
  confirmWithdrawalTron,
  confirmBanksWithdrawal,
} from "../modules/auth/core/_requests";
import { ConfirmTOTP } from "../pages/ConfirmTOTP";
import { UserContextProvider } from "../modules/profile/core/UserContextProvider";
import { ForgotPassword } from "../modules/auth/components/ForgotPassword";
import { ConfirmPassword } from "../modules/auth/components/ConfirmPassword";
import { AuthLayout } from "../modules/auth";
import { QueryWalletResponseProvider } from "../modules/profile/core/QueryWalletResponseProvider";
import StatisticPage from "../modules/statistic/StatisticPage";

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const SettingsPage = lazy(() => import("../modules/settings/SettingsPage"));
  const ProcessPage = lazy(() => import("../modules/process/ProcessPage"));
  const RulesPage = lazy(() => import("../modules/rules/RulesPage"));
  const VolutePage = lazy(() => import("../modules/volute/VolutePage"));
  const StatisticPage = lazy(
    () => import("../modules/statistic/StatisticPage")
  );
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));

  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );

  return (
    <UserContextProvider>
      <QueryWalletResponseProvider>
        <Routes>
          <Route
            path="freewin/confirm/*"
            element={<ConfirmOperation method={confirmFreewinWithdrawal} />}
          />
          <Route
            path="tron/confirm/*"
            element={<ConfirmOperation method={confirmWithdrawalTron} />}
          />

          <Route
            path="freewin/banks/confirm/*"
            element={<ConfirmOperation method={confirmBanksWithdrawal} />}
          />

          <Route path="totp/confirm/*" element={<ConfirmTOTP />} />

          <Route element={<AuthLayout />}>
            <Route path="auth/forgot-password" element={<ForgotPassword />} />
            <Route path="auth/reset-password/*" element={<ConfirmPassword />} />
          </Route>

          <Route element={<MasterLayout />}>
            {/* Redirect to Dashboard after success login/registartion */}
            <Route path="auth/*" element={<Navigate to="/profile" />} />
            {/* Pages */}

            <Route path="/" element={<MainPage />} />

            <Route
              path="profile/*"
              element={
                <SuspensedView>
                  <ProfilePage />
                </SuspensedView>
              }
            />
            <Route
              path="wizards/*"
              element={
                <SuspensedView>
                  <WizardsPage />
                </SuspensedView>
              }
            />
            <Route
              path="widgets/*"
              element={
                <SuspensedView>
                  <WidgetsPage />
                </SuspensedView>
              }
            />
            <Route
              path="accounts/*"
              element={
                <SuspensedView>
                  <AccountPage />
                </SuspensedView>
              }
            />

            <Route
              path="settings/*"
              element={
                <SuspensedView>
                  <SettingsPage />
                </SuspensedView>
              }
            />

            <Route
              path="rules/*"
              element={
                <SuspensedView>
                  <RulesPage />
                </SuspensedView>
              }
            />

            <Route
              path="process/*"
              element={
                <SuspensedView>
                  <ProcessPage />
                </SuspensedView>
              }
            />

            <Route
              path="statistic/*"
              element={
                <SuspensedView>
                  <StatisticPage />
                </SuspensedView>
              }
            />

            <Route
              path="volute/*"
              element={
                <SuspensedView>
                  <VolutePage />
                </SuspensedView>
              }
            />

            <Route
              path="chat/*"
              element={
                <SuspensedView>
                  <ChatPage />
                </SuspensedView>
              }
            />
            <Route
              path="user-management/*"
              element={
                <SuspensedView>
                  <UsersPage />
                </SuspensedView>
              }
            />
            {/* Page Not Found */}
            <Route path="*" element={<Navigate to="/error/404" />} />
          </Route>
        </Routes>
      </QueryWalletResponseProvider>
    </UserContextProvider>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
