import { KTIcon } from "../../../../helpers";

export function HeaderWallet() {
  return (
    <div className="d-flex align-items-center">
      <button className="d-none d-md-block btn btn-sm btn-primary me-2 ">
        <KTIcon
          iconName="key-square"
          className="d-none d-md-inline-block text-white fs-4 me-1 mb-1"
        />
        Продлить ключ
      </button>

      <div className="d-flex px-2 px-lg-4">
        <div className="d-flex px-3 ps-lg-5 pe-lg-2 py-1 border border-gray-400 border-dashed rounded  h-lg-45px cursor-text d-flex align-items-center">
          <span className="ki-solid ki-chart fs-2 text-freewin "></span>

          <div
            className="ms-1 ms-lg-2  d-lg-inline fs-5 fw-bold text-white flex-grow-1 text-start mb-lg-1"
            data-kt-element="title"
          >
            1 000,00 <span className="text-success">$</span>
          </div>

          <button className="btn btn-icon btn-create d-none d-lg-flex h-45px w-45px p-0">
            <i className="ki-duotone ki-plus fs-2x"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
