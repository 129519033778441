export const headerMenuList = [
  {
    href: "/accounts",
    name: "Аккаунты",
  },
  {
    href: "/settings",
    name: "Настройки",
  },
  {
    href: "/rules",
    name: "Правила",
  },
  {
    href: "/process",
    name: "Процесс",
  },
  {
    href: "/statistic",
    name: "Статистика",
  },
  {
    href: "/volute",
    name: "Валюта",
  },
  {
    href: "/profile",
    name: "Профиль",
  },
  {
    href: "/faq",
    name: "FAQ",
  },
];
