import { NavLink } from "../../../../../app/pages/main-page/components/NavLink";

import { headerMenuList } from "./header-menu-list";
import { useAuth } from "../../../../../app/modules/auth";

export function HeaderMenuWrapper() {
  const { currentUser } = useAuth();

  return (
    <>
      {currentUser && (
        <div
          className="d-none d-lg-block header-menu-container d-flex align-items-stretch flex-stack  w-100 "
          id="kt_header_nav"
        >
          <div className="header-menu container-xxl flex-column align-items-stretch flex-lg-row ">
            <div
              className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-700 menu-state-primary menu-arrow-gray-400 fw-semibold my-5 my-lg-0 align-items-stretch flex-grow-1 px-2 px-lg-0 justify-content-end gap-3"
              id="#kt_header_menu"
              data-kt-menu="true"
            >
              {headerMenuList.map((el: { href: string; name: string }) => (
                <div className="menu-item  menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2 ">
                  <span className="menu-link py-3">
                    <NavLink title={el.name} to={el.href} />
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
