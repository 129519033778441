import { useRef, useState } from "react";
import { KTIcon } from "../../../../helpers";
import { Withdraw } from "../../../../../app/modules/profile/components/Withdraw/Withdraw";

export function HeaderTopUp() {
  const mainBlockRef = useRef<HTMLDivElement | null>(null);
  const [isMenuUp, setIsMenuUp] = useState(false);

  const handleMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation(); // Предотвращаем всплытие события до основного блока
    setIsMenuUp((prev) => !prev);
  };

  return (
    <div className="position-relative d-none d-lg-flex flex-center">
      <button
        type="button"
        className="btn btn-sm"
        style={{ backgroundColor: "#f17401" }}
        onClick={handleMenuButtonClick}
      >
        <KTIcon iconName="wallet" className="text-white fs-4 me-1 mb-1" />
        Пополнить
      </button>

      {isMenuUp && (
        <div
          ref={mainBlockRef}
          className="position-absolute w-300px"
          style={{ top: "100%", right: "0%" }}
        >
          <Withdraw
            className="h-100"
            style={{
              background:
                "linear-gradient(90deg, rgb(11, 20, 34), rgb(4, 45, 86))",
            }}
          />

          <button
            className="modal-close btn btn-icon btn-sm btn-light-primary position-absolute top-0 end-0 m-5"
            onClick={handleMenuButtonClick}
          >
            <KTIcon className="fs-1" iconName="cross" />
          </button>
        </div>
      )}
    </div>
  );
}
