import axios, { AxiosError } from "axios";
import { AuthModel, TotpLoginModel, UserModel } from "..";
import {
  CreateCustomer,
  RefreshToken,
  SuccessResponse,
  TokenFrom2FA,
} from "../../../../api/core-contract";
import { useMutation } from "react-query";

const API_URL = process.env.REACT_APP_API_URL;

export const REGISTER_URL = `${API_URL}/signup`;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user`;

export const LOGIN_URL = `${API_URL}/login/auth`;

export const SET_2FA_CODE = `${API_URL}/login/v2/token`;

export const REFRESH_URL = `${API_URL}/login/refresh`;

export const LOGOUT = `${API_URL}/login/logout`;

export const REQUEST_PASSWORD_URL = `${API_URL}/auth/reset-password`;

export const RESET_PASSWORD = `${API_URL}/auth/update-password`;

export const REGISTRATION_CONFIRM = `${API_URL}/auth/registration`;

export const UPDATE_PASSWORD_CONFIRM = `${API_URL}/auth/update-password`;

export const FREEWIN_CONFIRM_WITHDRAW = `${API_URL}/freewin/confirm/withdraw`;

export const PS3838_CONFIRM_WITHDRAW = `${API_URL}/ps3838/confirm/withdraw`;

export const CONFIRM_WITHDRAW_TRON = `${API_URL}/tron/complete/withdraw`;

export const BANKS_CONFIRM_WITHDRAW = `${API_URL}/freewin/banks/confirm/withdraw`;

export const CHECK_LINKS = `${API_URL}/check_referral_links`;

export const CHECK_PROMOCODE = `${API_URL}/promo_code/check`;

export async function login(
  params: URLSearchParams,
  token: string
): Promise<AuthModel> {
  const headers = {
    "g-recaptcha-response": token,
  };

  const response = await axios.post<AuthModel>(LOGIN_URL, params, {
    headers: headers,
  });
  return response.data;
}

export const useConfirmLoginTotp = () => {
  return useMutation<AuthModel, AuthModel, TotpLoginModel>(
    "set-2fa-key",
    async (code: TotpLoginModel) => {
      const totpParams = new URLSearchParams(
        Object.entries(code).map(([key, value]) => [key, value ?? ""])
      );

      const { data } = await axios.post(SET_2FA_CODE, totpParams);
      return data;
    }
  );
};

export async function logoutFromServer(
  refreshToken: RefreshToken
): Promise<void> {
  await axios.delete(LOGOUT, { data: refreshToken });
}

export async function сheckPromocode(options: string): Promise<any> {
  const response = await axios.get(
    `${CHECK_PROMOCODE}?promo_code_data=${options}`
  );
  return response.data;
}

export async function register(
  email: string,
  nickname: string,
  password: string,
  promo: string,
  token: string,
  referral_link: string | null
): Promise<AuthModel> {
  const headers = {
    "g-recaptcha-response": token,
    "X-Service-Name": "freewin",
  };

  const response = await axios.post<AuthModel>(
    REGISTER_URL,
    {
      email,
      nickname,
      promo,
      password,
      referral_link,
    },
    {
      headers: headers,
    }
  );
  return response.data;
}

export async function refreshToken(
  refreshToken: RefreshToken
): Promise<AuthModel> {
  const params = new URLSearchParams();
  params.append("refresh_token", refreshToken.toString());

  const response = await axios.post<AuthModel>(REFRESH_URL, params);
  return response.data;
}

export async function updatePassword(
  token: string,
  password: string
): Promise<void> {
  await axios.post(
    UPDATE_PASSWORD_CONFIRM,
    { password },
    {
      params: { token },
    }
  );
}

export async function resetPassword(
  email: string
): Promise<{ result: boolean }> {
  const params = new URLSearchParams();
  params.append("email", email);

  const response = await axios.post<{ result: boolean }>(
    REQUEST_PASSWORD_URL,
    {},
    { params }
  );
  return response.data;
}

export async function getUserByToken(): Promise<UserModel> {
  const response = await axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL);
  return response.data;
}

export async function confirmRegistration(token: TokenFrom2FA): Promise<void> {
  await axios.post(REGISTRATION_CONFIRM, { token });
}

export async function confirmFreewinWithdrawal(
  token: TokenFrom2FA
): Promise<void> {
  const response = await axios.post(FREEWIN_CONFIRM_WITHDRAW, { token });
  return response.data;
}

export async function confirmPs3838Withdrawal(
  token: TokenFrom2FA
): Promise<void> {
  const response = await axios.post(PS3838_CONFIRM_WITHDRAW, { token });
  return response.data;
}

export async function confirmBanksWithdrawal(
  token: TokenFrom2FA
): Promise<void> {
  const response = await axios.post(BANKS_CONFIRM_WITHDRAW, { token });
  return response.data;
}

export async function confirmWithdrawalTron(
  token: TokenFrom2FA
): Promise<void> {
  const response = await axios.post(CONFIRM_WITHDRAW_TRON, { token });
  return response.data;
}


export async function checkLinks(
  referral_link: CreateCustomer["referral_link"] | null
): Promise<SuccessResponse | AxiosError> {
  const response = await axios.get(CHECK_LINKS, {
    params: { link: referral_link },
  });
  return response.data;
}
