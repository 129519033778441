import { Tooltip } from "bootstrap";
import type { FC } from "react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { useSearchParams, useNavigate } from "react-router-dom";
import { KTSVG } from "../../../_metronic/helpers";
import QRCode from "react-qr-code";
import { useConfirmTOTP } from "../../modules/profile/core/_requests";
import { UserContext } from "../../modules/profile/core/UserContextProvider";

interface Props {
  // method: (token: any) => void;
}

export const ConfirmTOTP: FC<Props> = React.memo(() => {
  const { updateTOTPStatus } = useContext(UserContext);

  const {
    mutate: setTOTPCode,
    isLoading: isTOTPDataLoading,
    data: TOTPData,
  } = useConfirmTOTP();

  const [tooltip, setTooltip] = useState<any>();
  const ref = useRef<HTMLButtonElement | null>(null);

  const [params] = useSearchParams();
  const navigate = useNavigate();

  const [TOTPQRCodeCopy, setTOTPQRCodeCopy] = useState<
    string | null | undefined
  >(null);

  useEffect(() => {
    if (TOTPData?.url) {
      updateTOTPStatus(true);

      const startIndex = TOTPData?.url.indexOf("secret=");

      if (startIndex !== -1) {
        const endIndex = TOTPData?.url.indexOf("&", startIndex);
        const code =
          endIndex !== -1
            ? TOTPData?.url.slice(startIndex + 7, endIndex)
            : TOTPData?.url.slice(startIndex + 7);
        setTOTPQRCodeCopy(code);
      }
    } else if (TOTPData) {
      navigate("/profile", {
        state: {
          action: {
            type: "ERROR_OPERATION_CONFIRM",
            title: "Статус подключения 2fa",
            text: "Ошибка подключения Google аутентификатора.",
          },
        },
      });
    }

    if (TOTPData && ref.current) {
      const tooltip = new Tooltip(ref.current, {
        trigger: "manual",
        title: "Скопированно",
        offset: [0, 5],
      });
      setTooltip(tooltip);
    }
  }, [TOTPData]);

  const copyToClipBoard = async (address: string | undefined | null) => {
    try {
      await navigator.clipboard.writeText(address ?? "");
      if (tooltip) {
        tooltip.show();
        setTimeout(() => {
          tooltip.hide();
        }, 800);
      }
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  useEffect(() => {
    const token = params.get("token");

    const confirm = () => {
      setTOTPCode({
        token: token,
      });
    };

    if (token) {
      confirm();
    } else {
      navigate("/profile/settings");
    }
  }, []);

  return (
    <Card className="p-15 w-md-50 m-auto">
      <div className="text-center mb-10">
        <div className="d-flex flex-column align-items-center">
          <h1 className="fw-semibold mb-3">
            Сохраните Google Authenticator ключ
          </h1>
          <div className="d-flex flex-center flex-column">
            <h3 className="text-gray-700 fw-bold mb-7">
              QR-код для подключения
            </h3>
            <div className="text-gray-500 fw-semibold fs-6">
              Используя приложение для аутентификации, такое как &nbsp;
              <a
                href="https://support.google.com/accounts/answer/1066447?hl=ru"
                target="_blank"
                rel="noreferrer"
              >
                Google Authenticator
              </a>
              , отсканируйте QR-код. Он сгенерирует 6-значный код, который вы
              сможете вводить.
            </div>
            {isTOTPDataLoading ? (
              <div
                aria-hidden="true"
                className="d-flex justify-content-center align-items-center p-5 bg-white rounded m-12 placeholder bg-light"
                style={{
                  width: "250px",
                  height: "250px",
                }}
              ></div>
            ) : (
              <>
                <div
                  className="p-5 bg-white rounded m-12"
                  style={{
                    width: "250px",
                    height: "250px",
                  }}
                >
                  {TOTPData?.url && (
                    <QRCode
                      size={256}
                      style={{
                        display: "block",
                        height: "auto",
                        width: "100%",
                        margin: "auto",
                      }}
                      value={TOTPData?.url}
                      viewBox={`0 0 256 256`}
                    />
                  )}
                </div>
              </>
            )}
          </div>
          {isTOTPDataLoading ? (
            <div
              className="spinner-border spinner-border-sm"
              role="status"
            ></div>
          ) : (
            <>
              <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
                <i className="ki-duotone ki-information fs-4x text-warning me-4">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>

                <div className="d-flex flex-stack flex-grow-1 ">
                  <div className=" fw-semibold">
                    <div className="fs-6 text-gray-700 ">
                      Если у вас возникли проблемы с использованием QR-кода,
                      выберите ручной ввод в приложении и введите свое имя
                      пользователя и код:
                      <div className="fw-bold text-gray-900 pt-2">
                        {TOTPQRCodeCopy ? (
                          TOTPQRCodeCopy
                        ) : (
                          <span className="spinner-border h-15px w-15px align-middle " />
                        )}
                        <button
                          ref={ref}
                          className="mx-3 p-0 btn btn-light btn-active-white"
                          style={{ background: "none" }}
                          onClick={() => copyToClipBoard(TOTPQRCodeCopy)}
                        >
                          <KTSVG
                            path="/media/icons/duotune/general/gen054.svg"
                            className="svg-icon-muted svg-icon-2hx m-0"
                          />
                        </button>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <button
            type="button"
            className="d-block btn btn-primary px-10 mt-3"
            disabled={isTOTPDataLoading}
            onClick={() => {
              navigate("/profile/settings");
            }}
          >
            Готово
          </button>
        </div>
      </div>
    </Card>
  );
});
